<template>
  <v-app id="login" class="warning">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md5 lg4>
            <v-form ref="form" v-on:submit.prevent="Login">
              <v-card class="elevation-1 pa-3">
                <v-card-text>
                  <div class="layout column align-center">
                    <img src="/static/m.png" alt="Sistema de Gesti&oacute;n Comercial - Lambda Servicios Informáticos">
                    <h1 class="flex my-4 warning--text">SGC - Acceso Restringido</h1>
                  </div>
                    <v-text-field 
                      append-icon="person" 
                      name="name" 
                      label="Nombre de Usuario" 
                      type="text"
                      autocomplete="username"
                      required
                      v-model="usuario.name"
                      :rules="nameRules"
                    >
                    </v-text-field>
                    <v-text-field 
                      append-icon="lock" 
                      name="password" 
                      label="Contraseña" 
                      id="password" 
                      type="password"
                      autocomplete="current-password"
                      required
                      v-model="usuario.password"
                      :rules="passwordRules"
                    >
                    </v-text-field>
                  <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
                </v-card-text>
                <v-card-actions>
                  <!--<v-btn icon title="Recuperar cuenta" color="primary" to="/recuperar">
                    <v-icon>lock_open</v-icon>
                  </v-btn>-->
                  <v-spacer></v-spacer>
                  <v-btn type="submit" block color="warning" :loading="isloading">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import alert from '@/components/alert/alert.vue'
import userService from '@/services/UserServices'
export default {
  layout: 'default',
  name: 'Login',
  components: {
    alert
  },
  data() {
    return {
      isloading: false,
      usuario: {name: '',password: ''},
      notificacion: {mensaje: '',tipo: 'error'},
      nameRules: [
        (v) => !!v || 'Ingrese un nombre de usuario'
      ],
      passwordRules: [
        (v) => !!v || 'Ingrese una contraseña'
      ]
    }
  },
  methods : {
    Login () {
      if(this.$refs.form.validate()){
        this.isloading = true
        userService.login(this.usuario.name,this.usuario.password)
          .then(res => {
            localStorage.clear();
            localStorage.access_token = res.access_token
            localStorage.user = window.atob(res.access_token.split('.')[1])
            this.$router.push('/')
          })
          .catch(err => {
            if(err.response && err.response.data.hasOwnProperty('errorMessage')){
              this.notificacion.mensaje = err.response.data.errorMessage;
            }else{
              switch(err.response.status){
                case 502:
                  this.notificacion.mensaje = 'El servidor no se encuentra disponible.';
                  break;
                default:
                  this.notificacion.mensaje = '(' + err.response.status + ')' + err.response.statusText;
              }
            }
          })
          .finally(() => {
            this.isloading = false
          })
      }
    }
  }
}
</script>